
import { defineComponent, PropType, computed } from "vue";
import FlatButton from "@/components/atomics/button/FlatButton.vue";
import { FileInfo } from "@/models/Template";
import Signer from "@/models/Signer";
import PdfViewer from "@/components/atomics/pdf-viewer/PdfViewer.vue";
import ItemInList from "@/components/atomics/list-item/ItemInList.vue";
import { downloadPDF } from "@/lib/utility/downloadFile";
import { useVModel } from "@vueuse/core";

export default defineComponent({
  name: "TemplateSummary",
  inheritAttrs: false,
  components: {
    FlatButton,
    PdfViewer,
    ItemInList,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    isSignerTransferAllowed: {
      type: Boolean,
      required: true,
    },
    files: {
      type: Array as PropType<Array<FileInfo>>,
      required: true,
    },
    signers: {
      type: Array as PropType<Array<Signer>>,
      required: true,
    },
  },
  emits: ["update:signers", "update:isSignerTransferAllowed"],
  beforeRouteEnter(to, from, next) {
    if (from.name == "TemplateVars" || to.params.id) next();
    else next({ name: "TemplateFileAndInfo" });
  },
  setup(props, { emit }) {
    const localSigners = computed({
      get(): Signer[] {
        return props.signers;
      },

      set(value: Signer[]) {
        emit(
          "update:signers",
          value.map((val, index) => ({
            order: index,
            placeHolder: val.placeHolder,
          }))
        );
      },
    });

    const localIsSignerTransferAllowed = useVModel(
      props,
      "isSignerTransferAllowed",
      emit
    );

    const removeAuthUser = (index: number) => {
      localSigners.value.splice(index, 1);
    };

    return {
      localSigners,
      removeAuthUser,
      downloadPDF,
      localIsSignerTransferAllowed,
    };
  },
});
