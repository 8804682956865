import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0840e5a9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "contract-title mt-3" }
const _hoisted_2 = { class: "label-title-c" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { class: "contract-area w-full mt-1" }
const _hoisted_5 = { class: "item-name w-full" }
const _hoisted_6 = { class: "item-content mt-2" }
const _hoisted_7 = { class: "paper" }
const _hoisted_8 = { class: "section-header big mt-5!" }
const _hoisted_9 = { class: "number" }
const _hoisted_10 = ["src"]
const _hoisted_11 = { class: "username ml-1" }
const _hoisted_12 = ["onClick", "src"]
const _hoisted_13 = {
  key: 1,
  class: "no-signer-message"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PdfViewer = _resolveComponent("PdfViewer")!
  const _component_FlatButton = _resolveComponent("FlatButton")!
  const _component_ItemInList = _resolveComponent("ItemInList")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t("template.summary.templateLabel")) + ":", 1),
      _createElementVNode("pre", _hoisted_3, _toDisplayString(` ${_ctx.title}`), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.files, (file) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "contract-item",
          key: file.id
        }, [
          _createElementVNode("p", _hoisted_5, _toDisplayString(file.name), 1),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_PdfViewer, {
                "file-id": file.id,
                src: file.path,
                page: 1
              }, null, 8, ["file-id", "src"])
            ])
          ]),
          _createVNode(_component_FlatButton, {
            class: "download-btn",
            text: _ctx.$t('common.download'),
            onOnClick: ($event: any) => (_ctx.downloadPDF(file.path))
          }, null, 8, ["text", "onOnClick"])
        ]))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t("template.summary.signerList")), 1),
    (_ctx.localSigners.length > 0)
      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.localSigners, (signer, index) => {
          return (_openBlock(), _createBlock(_component_ItemInList, {
            key: index,
            "has-divider": "",
            class: "signer-item"
          }, {
            head: _withCtx(() => [
              _createElementVNode("p", _hoisted_9, _toDisplayString(index + 1), 1)
            ]),
            body: _withCtx(() => [
              _createElementVNode("img", {
                class: "ml-1 bordered-avatar",
                src: require('icons/default-avatar.svg'),
                alt: "avatar"
              }, null, 8, _hoisted_10),
              _createElementVNode("p", _hoisted_11, _toDisplayString(signer.placeHolder), 1)
            ]),
            tail: _withCtx(() => [
              _createElementVNode("img", {
                onClick: ($event: any) => (_ctx.removeAuthUser(index)),
                src: require('icons/close-red-circle.svg'),
                alt: "icon delete"
              }, null, 8, _hoisted_12)
            ]),
            _: 2
          }, 1024))
        }), 128))
      : (_openBlock(), _createElementBlock("div", _hoisted_13, _toDisplayString(_ctx.$t("template.summary.noSigners")), 1))
  ], 64))
}